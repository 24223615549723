import React from "react"
import { graphql } from "gatsby"

import { Seo } from "../components"

import {
  ArticleHeader,
  Content,
  ContentWrapper,
  Sidebar,
  WeitereBlicke,
  Background,
} from "../sections/articles"

const BlickePage = ({ data }) => {
  const { title, uri, pagebuilder, featuredImage } = data.page

  const banner = featuredImage?.node.localFile?.childImageSharp.fluid.src

  return (
    <>
      <Seo
        title={title}
        description={pagebuilder.fixeElementeTop?.teaserText}
        banner={banner}
        pathname={uri}
        article
      />
      <ArticleHeader
        title={title}
        pageType="BLICKE"
        fixeElementeTop={pagebuilder.fixeElementeTop}
        weiterfuehrendeLinksUndDownloads={
          pagebuilder.weiterfuehrendeLinksUndDownloads
        }
      />

      <ContentWrapper>
        {pagebuilder.flexibleInhalte && (
          <Content
            isBlickePage
            content={pagebuilder.flexibleInhalte}
            bottomElemente={pagebuilder.fixeElementeBottom}
            linksAndDownloads={pagebuilder.weiterfuehrendeLinksUndDownloads}
          />
        )}
        {pagebuilder.sidebarElements && (
          <Sidebar data={pagebuilder.sidebarElements} />
        )}
      </ContentWrapper>
      <WeitereBlicke data={pagebuilder.eingabe} />
      <Background />
    </>
  )
}

export default BlickePage

export const query = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      uri
      title
      pagebuilder {
        ...PageTemplateElementeTopFragment
        ...PageTemplateInhalteFragment
        ...PageTemplateServiceTextFragment
        ...PageTemplateSidebarFragment
        ...PageTemplateWeitereBlicke
        ...PageTemplateLinksAndDownloadsFragment
      }
    }
  }
`
